import React from "react"
import BlogLayout from "gatsby-theme-blog/src/components/layout"

export default props => <BlogLayout {...props}>{props.children}</BlogLayout>

// Tell webpack this JS file uses this image
//import AspectAccompliImg from "/assets/aspect-accompli.png"
//import AspectIteratifImg from "/assets/aspect-iteratif.png"
//import AspectPonctuelImg from "/assets/aspect-ponctuel.png"
//import AspectProgressifImg from "/assets/aspect-progressif.png"

//export AspectAccompliImg;
